import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useMemo } from 'react';

const ekdPrimaryColor = '#F39200';
const ekdBlackColor = '#2C2C2C';
const BOX_SHADOW = {
  DEFAULT: 'rgb(0 0 0 / 14%) 0px 4px 5px 0px',
};

const FONT_SIZE = {
  DEFAULT: '0.875rem', // 14 px
};

/**
 *
 * @param {string} mode should be 'light' or 'dark'
 * @returns a mui theme
 */
const getTheme = mode =>
  createTheme({
    palette: {
      background: {
        default: 'transparent',
      },
      mode,
      common: {
        black: ekdBlackColor,
        white: '#ffffff',
      },
      primary: {
        main: ekdPrimaryColor,
        contrastText: '#fff',
        dark: '#dd852f',
      },
      secondary: {
        main: '#6B6B6B',
        dark: '#484848',
      },
      error: {
        main: '#E94A41',
        contrastText: '#fff',
      },
      warning: {
        main: '#EEC91D',
        contrastText: '#21262B',
      },
      success: {
        main: '#72A520',
        dark: '#678828',
        contrastText: '#fff',
      },
      info: {
        main: '#6A90FF',
        dark: '#6A90FF',
      },
      link: {
        main: '#727CF5',
      },
    },
    typography: {
      fontFamily: 'Palanquin, sans-serif',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    components: {
      MuiScopedCssBaseline: {
        defaultProps: {
          padding: 0,
        },
      },
      // force all typography components to receive correct font family:
      // https://github.com/mui/material-ui/issues/28633
      MuiTypography: {
        defaultProps: {
          fontFamily: 'Palanquin, sans-serif',
          color: 'rgba(0, 0, 0, 0.87)',
        },
        styleOverrides: {
          body1: {
            fontSize: FONT_SIZE.DEFAULT,
            lineHeight: '1.584rem',
            fontWeight: 400,
            whiteSpace: 'break-spaces',
          },
          caption: {
            fontSize: '0.75rem', // 12px
            lineHeight: '1.358rem',
            fontWeight: 400,
          },
          h4: {
            fontSize: '1.25rem', // 20px
            lineHeight: '2.264rem',
            fontWeight: 700,
          },
          h5: {
            fontSize: '1.125rem', // 18px
            lineHeight: '2.037rem',
            fontWeight: 700,
          },
          h6: {
            fontSize: '1rem', // 16px
            lineHeight: '1.811rem',
            fontWeight: 700,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#727CF5',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 4,
            fontWeight: 600,
            //boxShadow: BOX_SHADOW.DEFAULT,
            ':disabled': {
              pointerEvents: 'auto',
              cursor: 'not-allowed',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: BOX_SHADOW.DEFAULT,
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
      MuiChip: {
        styleOverrides: {
          sizeSmall: {
            paddingLeft: 8,
            paddingRight: 8,
          },
          sizeMedium: {
            paddingLeft: 16,
            paddingRight: 16,
          },
          icon: {
            marginLeft: 0,
          },
          label: {
            paddingRight: 0,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            marginTop: 24,
            marginBottom: 24,
            paddingTop: 8,
            paddingBottom: 8,
            paddingRight: 40,
            paddingLeft: 40,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 0,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
            paddingTop: 0,
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
          badge: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#000000',
          },
          textColorSecondary: {
            backgroundColor: ekdPrimaryColor,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: `1px solid ${ekdBlackColor}`,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: FONT_SIZE.DEFAULT,
            color: ekdBlackColor,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            fill: ekdBlackColor,
          },
          select: {
            fontSize: FONT_SIZE.DEFAULT,
            color: ekdBlackColor,
            padding: '0.5rem 0.75rem',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: FONT_SIZE.DEFAULT,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              fontSize: FONT_SIZE.DEFAULT,
              '& fieldset': {
                opacity: 0.6,
              },
              '&.Mui-focused fieldset': {
                opacity: 1,
              },
            },
            '& .MuiInputLabel-outlined': {
              opacity: 0.6,
            },
            '& .Mui-focused.MuiInputLabel-outlined': {
              opacity: 1,
            },
          },
        },
      },
    },
  });

const CustomThemeProvider = ({ children }) => {
  // const mode = useAppSelector(state => state.app.theme.mode)
  const theme = useMemo(() => getTheme('light'), []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
