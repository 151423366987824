import Container from '@mui/material/Container';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import React, { Suspense } from 'react';

import './App.css';
import { ApiContextProvider } from './context/ApiContext';
import { SnackbarProvider } from './context/SnackbarContext';
import TranslationContextProvider from './context/TranslationContext';
import CustomThemeProvider from './theme';

const componentMap = {
  ['ProtocolVisualDocumentationGallery'.toLowerCase()]: () =>
    import('./components/ProtocolVisualDocumentationGallery'),
  ['ServicePage'.toLowerCase()]: () =>
    import('./components/ServicePage/ServicePage'),
};

const App = ({ config, component }) => {
  // component and config can be pass via url. Example:
  //http://localhost:3000/?component=servicePage&leadId=7551
  //http://localhost:3000/?component=protocolVisualDocumentationGallery&leadId=7551&protocolType=AC

  const queryParams = new URLSearchParams(window.location.search);
  const queryComponent = queryParams.get('component');

  let resultConfig = config || {};
  let resultComponent = component || null;
  if (queryComponent) {
    resultComponent = queryComponent;
    resultConfig['leadId'] = queryParams.get('leadId');
    resultConfig['protocolType'] = queryParams.get('protocolType');
    resultConfig['includeAllAdditionalOrders'] = !!parseInt(
      queryParams.get('includeAllAdditionalOrders'),
    );
    resultConfig['additionalOrderId'] = queryParams.get('additionalOrderId');
    resultConfig['includeAllComplaints'] = !!parseInt(
      queryParams.get('includeAllComplaints'),
    );
    resultConfig['complaintId'] = queryParams.get('complaintId');
    resultConfig['onlyShowPvActivation'] = !!parseInt(
      queryParams.get('onlyShowPvActivation'),
    );

    resultConfig = Object.fromEntries(
      Object.entries(resultConfig).filter(
        ([_key, value]) => value !== undefined && value !== null,
      ),
    );
  }
  if (!resultComponent || !componentMap[resultComponent.toLowerCase()]) {
    return <div>Component not found</div>;
  }

  const Component = React.lazy(componentMap[resultComponent.toLowerCase()]);
  return (
    <ApiContextProvider baseUrl={resultConfig.baseUrl}>
      <CustomThemeProvider>
        <TranslationContextProvider>
          <SnackbarProvider>
            <ScopedCssBaseline>
              <Container disableGutters={true} maxWidth={false}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Component config={resultConfig} />
                </Suspense>
              </Container>
            </ScopedCssBaseline>
          </SnackbarProvider>
        </TranslationContextProvider>
      </CustomThemeProvider>
    </ApiContextProvider>
  );
};

export default App;
